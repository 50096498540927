<template>
  <div
    class="sidebar-detached sidebar-left"
    :class="{ 'is-loading': fLoading }"
  >
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              {{ $t('catalog.list.labels.filter') }}
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- status -->
          <div class="product-categories">
            <h6 class="filter-title mt-0">
              {{ $t('market.table.fields.status') }}
            </h6>
            <div class="item-buttons d-flex flex-column">

              <div class="d-flex justify-content-start align-items-center mb-75">
                <b-link :to="{ name: 'account:projects-index' }">
                  <b-avatar
                    rounded
                    size="32"
                    variant="light-primary"
                    class="mr-75"
                  >
                    <feather-icon
                      icon="CrosshairIcon"
                      size="16"
                    />
                  </b-avatar>
                </b-link>
                <b-link
                  :to="{ name: 'account:projects-index' }"
                >
                  <div
                    class="blog-category-title text-body"
                    v-text="$t('general.projectStatus.all')"
                  />
                </b-link>
              </div>
              <div
                v-for="status in filterOptions"
                :key="status.value"
                class="d-flex justify-content-start align-items-center mb-75"
              >
                <b-link
                  :to="{ name: 'account:projects-index', query: { status: status.value } }"
                >
                  <b-avatar
                    rounded
                    size="32"
                    :variant="`light-${status.color}`"
                    class="mr-75"
                  >
                    <feather-icon
                      :icon="status.icon ? status.icon : 'BookmarkIcon'"
                      size="16"
                    />
                  </b-avatar>
                </b-link>
                <b-link
                  :to="{ name: 'account:projects-index', query: { status: status.value } }"
                >
                  <div class="blog-category-title text-body">
                    {{ status.label }}
                  </div>
                </b-link>
              </div>
            </div>
          </div>

        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, VBTooltip, BLink, BAvatar,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
  },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    fLoading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.router-link-exact-active {
  font-weight: bold;
}

.item-stickers {
  display: flex;
  gap:5px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin:0 0;

  .item-stickers-item {
    width: 40px;
    height: 40px;
    background: rgba(238, 238, 238, 0.6);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 25px;
      max-height: 25px;
    }
  }

  input {
    display: none;
  }

  input:checked {

    & + .item-stickers-item {
      background: #7367f0;
    }
  }

  &.lang {

    img {
      border-radius: 50%;
    }
  }
}

.item-buttons {
  display: flex;
  gap:5px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin:0 0;

  input {
    display: none;

    & + .btn {
      padding: 0.786rem 1rem;
    }
  }

  input:checked {

    & + .btn {
      border-color: #7367f0 !important;
      background-color: #7367f0 !important;
      color:#fff !important;
    }
  }
}
</style>
